import React from 'react';
import './404.scss';
import loadable from '@loadable/component';


class HelloWorld extends React.Component {
    render() {
      const Layout = loadable(() => import ('../components/layout'));
      return (
        <Layout className="strawberry-style">
          <div className="mining-header">
            <h1>Oops! An unexpected error has ocurred.</h1>
            </div>
      </Layout>
      );
    }
  }
  
  export default HelloWorld;